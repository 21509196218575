<template>
    <div class="app-container">

        <div class="filter-container">
            <el-input style="flex: 1" class="item" :placeholder="'Search organisations'" v-model="search"/>
        </div>

        <el-table
            :data="filtered_orgs" fit
            v-loading="loading"
            :span-method="spanColumns"
            @row-click="viewOrg"
            @sort-change="sortOrgs"
        >

            <el-table-column label="Organisation" prop="name" sortable="custom" />

            <el-table-column label="Subscription Period" prop="subscription" width="200" sortable="custom">
              <template slot-scope="scope">
                <span v-if="scope.row.active_subscription">
                  {{ scope.row.active_subscription.start_date | dateformat }}
                  - {{ scope.row.active_subscription.end_date | dateformat }}
                </span>
                <span v-else>
                  <el-tag type="info" effect="plain" disable-transitions>No active subscription</el-tag>
                </span>
              </template>
            </el-table-column>

            <el-table-column label="Price" prop="subscription" width="80" sortable="custom">
              <template slot-scope="scope">
                  <span v-if="scope.row.active_subscription && scope.row.active_subscription.price">
                    {{ scope.row.active_subscription.price | currency }}
                  </span>
              </template>
            </el-table-column>

            <el-table-column label="Ticket Duration" prop="duration" width="140" sortable="custom">
              <template slot-scope="scope">
                  <span v-if="scope.row.active_subscription">
                    {{ scope.row.active_subscription.ticket_duration }} months
                  </span>
              </template>
            </el-table-column>

            <el-table-column label="Tickets" prop="tickets" width="200" sortable="custom">
              <template slot-scope="scope" v-if="scope.row.active_subscription">
                  <el-tag v-if="scope.row.active_subscription.ticket_count > 0" :type="subscriptionHealth(scope.row)">
                    {{ scope.row.active_subscription.tickets_claimed }}
                    / {{ scope.row.active_subscription.ticket_count }}
                    <span v-if="scope.row.waiting_count > 0">&nbsp; ( {{ scope.row.waiting_count }} waiting )</span>
                  </el-tag>
                  <el-tag v-else>
                    {{ scope.row.active_subscription.tickets_claimed }} / unlimited
                  </el-tag>
              </template>
            </el-table-column>

            <el-table-column label="Total" prop="total_tickets" width="75" sortable="custom">
              <template slot-scope="scope">
                <el-tag type="info">
                  {{ scope.row.total_tickets }}
                </el-tag>
              </template>
            </el-table-column>

<!--
            <el-table-column label="Subscription Status" prop="subscription_days" width="180">
              <template slot-scope="scope">
                <el-tag v-if="scope.row.subscription_days >= 0" :disable-transitions="true" :type="(scope.row.subscription_days > 7) ? 'info' : 'warning'">
                  {{scope.row.subscription_days}} {{'day' | pluralize(scope.row.subscription_days)}} left
                </el-tag>
                <el-tag v-else :disable-transitions="true" type="danger">
                  expired
                </el-tag>
              </template>
            </el-table-column>

            <el-table-column label="Subscribers" prop="participants" width="130">
              <template slot-scope="scope">
                <el-tag :disable-transitions="true" :type="
                  (scope.row.subscribers > scope.row.subscription_limit) ? 'danger' : ( (scope.row.subscribers / scope.row.subscription_limit < 0.75) ? 'info' : 'warning' )">
                  {{scope.row.subscribers}} / {{scope.row.subscription_limit}}
                </el-tag>
              </template>
            </el-table-column>
-->
        </el-table>

        <el-pagination
          v-if="query.pagecount"
          layout="prev, pager, next"
          :current-page="query.page"
          :page-count="query.pagecount"
          @current-change="getOrgs"
          >
        </el-pagination>

    </div>
</template>

<script>
    import _ from 'lodash';
    import moment from 'moment';
    import axios from 'axios';
    import auth from '@/utils/auth';

    export default {
        name: 'orgs',
        props: {
          view: String,
        },
        data() {
            return {
                orgs: [],
                loading: true,
                search: '',
                debouncedSearch: '',
                query: {
                    page: 1,
                    limit: 15,
                    total: null,
                    pagecount: null,
                    orderBy: 'name',
                    orderDir: 'ascending',
                },
            };
        },
        mounted() {
            this.getOrgs(1);
        },
        watch: {
            search(value) {
                this.searchOrgs(value);
            },
        },

        computed: {
            filtered_orgs() {
              return this.orgs.map(org => {
                if(org.subscriptions) {
                    org.active_subscription = org.subscriptions.find(sub => sub.deleted_at == null && sub.is_active);
                }
                return org;
              }).filter(org =>
                  org.name.toLowerCase().includes(this.debouncedSearch.toLowerCase())
              ).sort((org_a,org_b) => {

                // for subscription based sort, always return "no subscription" below other results
                if(this.query.orderBy === "subscription" ||
                    this.query.orderBy === "price" ||
                    this.query.orderBy === "duration" ||
                    this.query.orderBy === "tickets") {
                  if(!org_a.active_subscription && !org_b.active_subscription) return 0;
                  if(!org_a.active_subscription) return 1;
                  if(!org_b.active_subscription) return -1;
                }
                // set sort direction
                const a = (this.query.orderDir === 'descending') ? org_b : org_a;
                const b = (this.query.orderDir === 'descending') ? org_a : org_b;

                switch(this.query.orderBy) {
                  case "name": {
                    const aName = a.name.toLowerCase();
                    const bName = b.name.toLowerCase();
                    if (aName < bName) return -1;
                    if (aName > bName) return 1;
                    return 0;
                  }
                  case "subscription": {
                    return a.active_subscription.start_date.unix() - b.active_subscription.start_date.unix();
                  }
                  case "price": {
                    return a.active_subscription.price - b.active_subscription.price;
                  }
                  case "duration": {
                    return a.active_subscription.ticket_duration - b.active_subscription.ticket_duration;
                  }
                  case "tickets": {
                    return a.active_subscription.tickets_available - b.active_subscription.tickets_available;
                  }
                }
              });
            }
        },

        methods: {
            async getOrgs(page) {
              if(page) {
                this.query.page = page;
              }

              this.loading = true;

              var query = `${process.env.VUE_APP_BASE_URI}/organisations?includes[]=subscriptions`;
              // if(this.debouncedSearch) {
              //   query += `&search=${encodeURIComponent(this.debouncedSearch)}`;
              // }

              const token = await this.$auth.getTokenSilently();

              axios.get(query,{
                headers: {
                  //'Authorization': `Bearer ${process.env.VUE_APP_TEMP_AUTH_TOKEN}`
                  'Authorization': `Bearer ${token}`
                }
              })
              .then(response => {
                  // response generated via external API; data is not paginated

                  const data = response.data.data;
                  var orgs = [];

                  if(data && data.organisations) {
                    data.organisations.forEach(org => {
                      orgs.push({
                        id: org.id,
                        name: org.name,
                        waiting_count: org.waiting_count,
                        total_tickets: org.total_tickets,
                        subscriptions: org.subscriptions,
                      });
                    });
                  }
                  this.orgs = orgs;
                  this.loading = false;
              })
              .catch((error) => {
                  this.orgs = [];
                  this.loading = false;
                  console.log("API error",error,error.response,error.response.status);
                  if(error.response.status == 400) {
                    // bad token
                    auth.logout();
                    this.$router.push('/login?err=token');
                  }
              });

            },

            searchOrgs: _.debounce(function(value) {
              this.debouncedSearch = value;
              //this.getOrgs(1);
            },200),

            sortOrgs({prop, order}) {
              // if order is null, no sort is specified; default to name ascending
              if(!order) {
                this.query.orderBy = "name";
                this.query.orderDir = "ascending";
              } else {
                this.query.orderBy = prop;
                this.query.orderDir = order;
              }
            },

            viewOrg(org) {
              this.$router.push(`/org/${org.id}`)
            },

            subscriptionHealth(org) {
              const claimRatio = org.active_subscription.tickets_available / org.active_subscription.ticket_count;
              if(claimRatio < 0.1) return "danger";
              if(claimRatio < 0.5) return "warning";
              return "success";
            },

            spanColumns({ row, column, rowIndex, columnIndex}) {
              if(!row.active_subscription) {
                if(columnIndex === 1) {
                  return [1, 4];
                }else if(columnIndex > 1 && columnIndex <= 4) {
                  return [0, 0];
                } else {
                  return[1,1];
                }
              }
              return [1,1];
            },

        },
    };
</script>

<style rel="stylesheet/scss" lang="scss" scoped>

    .filter-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

    }

    .full-width {
        width: 100%;
    }

    .el-tag {
        width: 100%;
        text-align: center;
        padding-left: 20px;
        padding-right: 20px;
        font-size: 13px;
        font-weight: bold;
    }

    /deep/ .el-table__row {
      cursor: pointer;
    }

    /deep/ .el-pagination {
      text-align: center;
      button, li {
        background: transparent;
      }
    }

</style>
