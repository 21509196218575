var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("el-input", {
            staticClass: "item",
            staticStyle: { flex: "1" },
            attrs: { placeholder: "Search organisations" },
            model: {
              value: _vm.search,
              callback: function ($$v) {
                _vm.search = $$v
              },
              expression: "search",
            },
          }),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: {
            data: _vm.filtered_orgs,
            fit: "",
            "span-method": _vm.spanColumns,
          },
          on: { "row-click": _vm.viewOrg, "sort-change": _vm.sortOrgs },
        },
        [
          _c("el-table-column", {
            attrs: { label: "Organisation", prop: "name", sortable: "custom" },
          }),
          _c("el-table-column", {
            attrs: {
              label: "Subscription Period",
              prop: "subscription",
              width: "200",
              sortable: "custom",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.active_subscription
                      ? _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("dateformat")(
                                  scope.row.active_subscription.start_date
                                )
                              ) +
                              " - " +
                              _vm._s(
                                _vm._f("dateformat")(
                                  scope.row.active_subscription.end_date
                                )
                              ) +
                              " "
                          ),
                        ])
                      : _c(
                          "span",
                          [
                            _c(
                              "el-tag",
                              {
                                attrs: {
                                  type: "info",
                                  effect: "plain",
                                  "disable-transitions": "",
                                },
                              },
                              [_vm._v("No active subscription")]
                            ),
                          ],
                          1
                        ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "Price",
              prop: "subscription",
              width: "80",
              sortable: "custom",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.active_subscription &&
                    scope.row.active_subscription.price
                      ? _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("currency")(
                                  scope.row.active_subscription.price
                                )
                              ) +
                              " "
                          ),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "Ticket Duration",
              prop: "duration",
              width: "140",
              sortable: "custom",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.active_subscription
                      ? _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                scope.row.active_subscription.ticket_duration
                              ) +
                              " months "
                          ),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "Tickets",
              prop: "tickets",
              width: "200",
              sortable: "custom",
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function (scope) {
                    return scope.row.active_subscription
                      ? [
                          scope.row.active_subscription.ticket_count > 0
                            ? _c(
                                "el-tag",
                                {
                                  attrs: {
                                    type: _vm.subscriptionHealth(scope.row),
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        scope.row.active_subscription
                                          .tickets_claimed
                                      ) +
                                      " / " +
                                      _vm._s(
                                        scope.row.active_subscription
                                          .ticket_count
                                      ) +
                                      " "
                                  ),
                                  scope.row.waiting_count > 0
                                    ? _c("span", [
                                        _vm._v(
                                          "  ( " +
                                            _vm._s(scope.row.waiting_count) +
                                            " waiting )"
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              )
                            : _c("el-tag", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      scope.row.active_subscription
                                        .tickets_claimed
                                    ) +
                                    " / unlimited "
                                ),
                              ]),
                        ]
                      : undefined
                  },
                },
              ],
              null,
              true
            ),
          }),
          _c("el-table-column", {
            attrs: {
              label: "Total",
              prop: "total_tickets",
              width: "75",
              sortable: "custom",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-tag", { attrs: { type: "info" } }, [
                      _vm._v(" " + _vm._s(scope.row.total_tickets) + " "),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm.query.pagecount
        ? _c("el-pagination", {
            attrs: {
              layout: "prev, pager, next",
              "current-page": _vm.query.page,
              "page-count": _vm.query.pagecount,
            },
            on: { "current-change": _vm.getOrgs },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }